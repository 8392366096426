export const Vision = (props) => {
  return (
    <div id="vision">
      <div className="container">
        <div className='section-title text-center'>
          <h2>Vision & Mission</h2>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="vision-text">
              <h3>VISI</h3>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              {/* <h3>MISI</h3>
              <p>{props.data ? props.data.paragraph2 : "loading..."}</p> */}
              <h3>MISI</h3>
              <div className="list-style">
                <div>
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                {/* <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            {/* {" "} */}
            <div class="gallery"> 
                <div class="gallery__item gallery__item--1">
                  <img src="img/cargo3.jpg" className="gallery__img" alt="" />
                </div>
                <div class="gallery__item gallery__item--2">
                  <img src="img/cargo4.jpg" className="gallery__img" alt="" />
                </div>
                <div class="gallery__item gallery__item--3">
                  <img src="img/cargo5.jpg" className="gallery__img" alt="" />
                </div>
            </div>
            {/* {" "} */}
          </div>
        </div>
      </div>
    </div>
  );
};
